import React, { useEffect } from 'react';
import Navbar from './Navbar';
import { FaArrowRightLong } from "react-icons/fa6";
import Contact from './Contact';
import Footer from './Footer';
import { motion } from 'framer-motion';
import bulker from '../Assets/bulker.png'
import Shortcodee from '../Assets/vasa.png'
import ussdmenus from '../Assets/ivrrr.png'
import smsresell from '../Assets/smsresell.png'
import { MdKeyboardArrowRight } from "react-icons/md";


const Service = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);
    return (
        <div className='service-container'>
            <Navbar />
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                delay: 0.0008,
                duration: 0.9
            }} className='service-page-one'>
                <h3 className='service-header'>Services</h3>
                <h4 className='service-header-two'>We empower business of all sizes to connect with their customers. Our cutting-edge VAS and Bulk SMS platforms are designed to streamline communication, boost engagement, and help you achieve your marketing goals.</h4>
            </motion.div>
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                y: { type: "spring", stiffness: 130 },
                opacity: { duration: 1 },
                ease: "easeIn",
                delay: 0.0008,
                duration: 1.2
            }} className='service-page-two' id="bulk-sms">
                <div className='shortCodes shortCodes1'>
                <div className='thebulk'>
                    <h3 className='vas'>Bulk SMS</h3>
                    <h4 className='eliminate'>Stop missing out on customer engagement!</h4>
                    <h4 className='eliminate-white'> We offer a powerful bulk SMS service built on a stable and reliable platform that caters to needs of businesses of all sizes, helping you connect with your audience in a personalized and impactful way.</h4>
                    <h4 className='eliminate'>Our platform gives you complete control:</h4>
                    <ul className='serve-list'>
                        <li><MdKeyboardArrowRight style={{ color: "#000" }} /><b> Tailor-made for you:</b> Unlike a one-size-fits-all solutions, our platform gives your business the flexibility to design campaign strategies that align perfectly with your brand and customer engagement goals.</li>
                        <li><MdKeyboardArrowRight style={{ color: "#000" }} /><b> Seamless integrations:</b> Integrate our SMS service smoothly with your existing CRM, marketing automation tools, or e-commerce platform.</li>
                        <li><MdKeyboardArrowRight style={{ color: "#000" }} /><b> Advanced features:</b> Leverage features like scheduled campaigns, personalized content based on customer data, and detailed analytics to optimize your strategy.</li>
                        <li><MdKeyboardArrowRight style={{ color: "#000" }} /><b> Measurable results:</b> Gain valuable insights into campaign performance and optimize for success.</li>
                    </ul>
                   {/* <h4 className='eliminate'>Engagement Solutions for Every Business:</h4>
                    <ul className='serve-list'>
                        <li><MdKeyboardArrowRight style={{ color: "#000" }} /><b> Retail & E-commerce:</b> Promote flash sales, abandoned cart reminders, exclusive offers, account verification and loyalty programs.</li>
                        <li><MdKeyboardArrowRight style={{ color: "#000" }} /><b> Restaurants & Hospitality:</b> Send reservation confirmations, order updates, and special promotional messages.</li>
                        <li><MdKeyboardArrowRight style={{ color: "#000" }} /><b> Professional Services & B2B: </b>  Deliver appointment reminders, client updates, account verification and industry news directly to their phones.</li>
                    </ul>
                    <h4 className='eliminate'> Ready to take your customer engagement to the next level?</h4>
                    <h4 className='eliminate-white'> Our team of experts will guide you through every step. We offer flexible pricing plans to suit your needs, and our robust platform ensures reliable message delivery and security.</h4>*/}
                </div>
                <div class="smsimg" style={{ width: '40%', }} >
                    <img className='serveimg-one' src={bulker} alt='' />
                </div>
                </div>
            </motion.div>
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                delay: 0.0008,
                duration: 0.9
            }} className='serveimg'>
                <img className='serveimg-two' style={{ width: '58rem', height: '100%' }} src={bulker} alt='' />
            </motion.div>
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                delay: 0.0008,
                duration: 0.9
            }} className='service-page-three' id='vas'>
                <div className='shortCodes'>
                    <img className='shortimg' style={{ height: '100%', }} src={Shortcodee} alt='' />

                    <div className='vassa'>
                        <h3 className='vas-white'>Value Added Services (VAS)</h3>
                        <h4 className='eliminate'>Unlock Feature-Rich Mobile Engagement: USSD Services and USSD Menu Development for All Businesses</h4>
                        <h5 className='eliminate-white-mini'>We offer a powerful solution for direct customer engagement through USSD (Unstructured Supplementary Service Data) services and expert USSD menu development. Our platform empowers businesses of all sizes to create user-friendly and interactive mobile experiences using USSD.</h5>

                        {/*<h4 className='vas-white-mini'>Simplify Customer Interaction:</h4>*/}

                        <ul className='serve-list-white'>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Simplify Customer Interaction:</b> We offer feature-rich menus, offline functionality, and cost-effective solutions that allow you to interact with your customers in a cost-effective way.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Menu Development Expertise:</b> From intuitive navigation, branded experience, to ensuring a seamless integration that prioritizes a safe a reliable communication we help our clients implement USSD solutions that transform customer interaction.</li>
                            {/*<li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Cost-effective solution:</b> USSD offers a cost-effective way to reach customers, particularly in regions with limited data access.</li>*/}
                        </ul>
                        {/*<h4 className='vas-white-mini'>Menu Development Expertise:</h4>
                        <ul className='serve-list-white'>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Intuitive navigation:</b> We design clear and concise USSD menus that are easy for users to understand and navigate.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Branded experience:</b> Integrate your brand identity into the USSD menus for a consistent customer experience.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Seamless integration:</b> Integrate your USSD service with our platform for data exchange and real-time updates.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Security focus:</b> We prioritize robust security measures to ensure safe and reliable communication.</li>
                        </ul>
                        <h4 className='vas-white-mini'>Empower Your Business with USSD:</h4>
                        <ul className='serve-list-white'>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Financial Services:</b> Provide account balance inquiries, mobile money transfers, and bill payments.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Retail & E-commerce:</b> Offer product information, check loyalty points, and promote special offers.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Customer Service:</b> Facilitate self-service options for account management, troubleshooting, and feedback.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Media & Entertainment:</b> Deliver content updates, voting options, and subscription management.</li>
                        </ul>
                        <h4 className='eliminate'>Ready to leverage the power of USSD?</h4>
                        <h4 className='eliminate-white'> Contact us today for a free consultation and discover how USSD services can transform your customer interactions!</h4>*/}
                    </div>
                </div>

            </motion.div>
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                delay: 0.0008,
                duration: 0.9
            }} className='service-page-two' id='ivr'>
                <div className='ussdmenus ussdmenus1'>
                    <div className='ussdmenus-text'>
                        <h3 className='vas'>Interactive Voice Response (IVR)</h3>
                        <h4 className='eliminate'> Streamline Customer Interactions with Intelligent IVR Systems: Solutions for Every Business</h4>
                        {/*<h5 className='eliminate-white-mini'>We offer a powerful solution for direct customer engagement through USSD (Unstructured Supplementary Service Data) services and expert USSD menu development. Our platform empowers businesses of all sizes to create user-friendly and interactive mobile experiences using USSD.</h5>*/}
                        <h4 className='eliminate'> Craft a Personalized Voice Experience:</h4>
                        <ul className='serve-list-white'>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Intelligent menus:</b> Design multi-level IVR menus with clear prompts and options for a smooth user experience.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Speech recognition and text-to-speech:</b> Utilize advanced technology for natural voice interaction and clear audio prompts.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Dynamic routing:</b> Route callers efficiently based on their needs, reducing wait times and frustration.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Seamless integrations:</b> Integrate our IVR system with your CRM for real-time data access.</li>
                        </ul>
                        {/*<h4 className='eliminate'> Benefits You Can't Ignore:</h4>
                        <ul className='serve-list-white'>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Improved customer satisfaction:</b> Reduce wait times and empower customers to solve issues independently.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Increased efficiency:</b> Free up agents for complex inquiries, optimizing call centre operations.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>24/7 Availability: </b> Provide self-service options around the clock, even outside business hours.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Reduced operational costs:</b> IVR systems can significantly lower call centre expenses.</li>
                        </ul>
                        <h4 className='eliminate'> IVR Solutions for Diverse Industries:</h4>
                        <ul className='serve-list-white'>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Healthcare:</b> Provide appointment scheduling, medication reminders, and insurance information.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Financial Services: </b> Offer account balance inquiries, bill payments, and secure transactions.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>E-commerce:</b> Enable order tracking, returns management, and product information access.</li>
                            <li><MdKeyboardArrowRight style={{ color: "#000000" }} /><b>Travel & Hospitality: </b> Facilitate flight confirmations, hotel reservations, and loyalty program management.</li>
                        </ul>*/}
                        <h4 className='eliminate'>Ready to transform your customer service experience?</h4>
                        <h4 className='eliminate-white'> Our team will guide you through every step, from design and development to implementation and ongoing support. We offer flexible pricing plans to fit your budget. Contact us today for a free consultation and discover how our IVR system can revolutionize your customer interactions!</h4>
                    </div>
                    <img className='menusclass-one' style={{}} src={ussdmenus} alt='' />
                </div>
            </motion.div>
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                delay: 0.0008,
                duration: 0.9
            }} className='menusclass-two'>
                <img style={{ height: '12em', }} src={ussdmenus} alt='' />
            </motion.div>
            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                y: { type: "spring", stiffness: 60 },
                opacity: { duration: 1 },
                ease: "easeIn",
                delay: 0.0008,
                duration: 0.9
            }} className='service-page-three' id='srs'>
                <div className='ussdmenus'>
                    <img className='shortimg' style={{ height: '100%', }} src={smsresell} alt='' />

                    <div className='ussdmenus-text'>
                        <h3 className='vas-white'>SMS Reseller Solution</h3>
                        <h4 className='eliminate-white'
                        > <strong>Unlock a new revenue stream!</strong> Become a BulkSMS Reseller and start profiting today. Buy SMS credits in bulk at discounted rates, then resell them to your customers for a tidy profit. It's a win-win! Our reseller solution helps you to become a one-stop shop for businesses needing reliable and cost-effective bulk SMS solutions.
                        </h4>
                    </div>
                </div>
            </motion.div>

            <Contact />
            <Footer />
        </div >
    )
}

export default Service
