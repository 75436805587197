import React, { useState, useEffect } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';
import { IoBagCheckSharp } from "react-icons/io5";
import { CiCalendarDate } from "react-icons/ci";
import { GiOpenBook } from "react-icons/gi";
import { BsClock } from "react-icons/bs";
import { WiDayFog } from "react-icons/wi";
import { RxDotFilled } from "react-icons/rx";
import { MdCloudUpload } from "react-icons/md";
import Success from './Success';
import Man from "../Assets/3dman.jpg";
import { motion } from 'framer-motion';
import axios from 'axios';
//import { getSessionData } from './helpers/SessionHelper';
import Failed from "./Failed";
import fail from "../Assets/failed.png";
import { useParams } from 'react-router-dom';
import SimpleBackdrop from './SimpleBackdrop';
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom';
import { setJobs } from '../redux/reducers/jobReducer';
//import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";

const InCareer = () => {
    const [mockdata, setMockdata] = useState({});
    const [resume, setResume] = useState('');
    const [cover, setCover] = useState('');
    const [resumename, setResumename] = useState('');
    const [covername, setCovername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phonenumber, setPhonenumber] = useState('');
    const [salary, setSalary] = useState('');
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFailedOpen, setIsFailedOpen] = useState(false);
    const { career } = useParams();
    const [submit, setSubmit] = useState(false);
    const jobs = useSelector((state) => state.job.jobs);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        //setData()
        const slblog = jobs.filter(x=>x.id==Number(career))[0];
        console.log(slblog, 'selblog')
        if(slblog)
            setMockdata(slblog);
        else{
            setData()
        } //navigate('/careers')
    }, [])

    async function setData() {
        //let job = getSessionData('jobid')
        try {
            await axios.get('https://api.geminigroupng.com/get-jobs')
            .then((res) => {
                setErrorMessage("")
                // res.json()
                const jobs = res.data.data.reverse();
                const job = jobs.filter(x=>x.id==Number(career))[0];
                setMockdata(job);
                dispatch(setJobs(jobs))
                console.log(jobs, 'jobs')
            })
        } catch (error) {
            // console.log(error.toJSON().message);
            setErrorMessage(error.toJSON().message);
        }
    }

    async function onSubmit(e) {
        e.preventDefault();
        setSubmit(true);
        try {
            let url = `https://api.geminigroupng.com/apply-for-job`;
            const JobID = mockdata.id;

            const formData = new FormData();

            formData.append("first_name", firstname)
            formData.append("last_name", lastname)
            formData.append("email", email)
            formData.append("phone_num", phonenumber)
            formData.append("salary", salary)
            formData.append("file", resume)
            formData.append("job_id", JobID)
            formData.append('job_title', mockdata.title)
            //formData.append("cover_letter", cover)

            await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                setErrorMessage("")
                setSubmit(false);
                setIsSuccessOpen(!isSuccessOpen);
            })
        } catch (error) {
            setSubmit(false);
            setErrorMessage(error.toJSON().message);
            setIsFailedOpen(true);
        }
    };

    const handleFile = (event) => {
        setErrorMessage("")
        if (event.target.files[0]) {
            setResumename(event.target.files[0].name)
            setResume(event.target.files[0]);
        }
    }

    const handleFiles = (event) => {
        setErrorMessage("")
        if (event.target.files[0]) {
            setCovername(event.target.files[0].name)
            setCover(event.target.files[0]);
        }
    }

    return (
        <div className='careers-container'>
            <Navbar />
            {
                mockdata.id && <>
                    <div className='careers-page-tone'>
                        <motion.div initial={{ x: -100, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{
                            x: { type: "spring", stiffness: 60 },
                            opacity: { duration: 1 },
                            ease: "easeIn",
                            delay: 0.0008,
                            duration: 0.9
                        }} className='first-div'>
                            <h2 className='first-header'>{mockdata !== 'undefined' && mockdata.title}</h2>
                            <h4 className='second-header'>Job description</h4>
                            <ul className='first-list'>
                                <li>{mockdata !== 'undefined' && <FroalaEditorView model={mockdata.description} />}</li>

                            </ul>

                            <h3 className='head-first'>
                                Job details
                            </h3>
                            <div className='body-first'>
                            <FroalaEditorView model={mockdata.details} />
                            </div>
                        </motion.div>
                        <motion.div initial={{ x: 100, opacity: 0 }} whileInView={{ x: 0, opacity: 1 }} transition={{
                            x: { type: "spring", stiffness: 60 },
                            opacity: { duration: 1 },
                            ease: "easeIn",
                            delay: 0.0008,
                            duration: 0.9
                        }} className='second-div'>
                            <h4 className='third-header'>Job Summary</h4>
                            <div className='small-row'>
                                <IoBagCheckSharp className='check-sharp' />
                                <div className='small-col'>
                                    <h5 className='fourth-header'>Job Type</h5>
                                    <h5 className='fifth-header'>{mockdata.employment_type}</h5>
                                </div>
                            </div>
                            <div className='small-row'>
                                <CiCalendarDate className='check-sharp' />
                                <div className='small-col'>
                                    <h5 className='fourth-header'>Date Posted</h5>
                                    <h5 className='fifth-header'>{mockdata.created_at.replace('T',' ').slice(0,-8)}</h5>
                                </div>
                            </div>
                            <div className='small-row'>
                                <GiOpenBook className='check-sharp' />
                                <div className='small-col'>
                                    <h5 className='fourth-header'>Experience</h5>
                                    <h5 className='fifth-header'>{mockdata !== 'undefined' && mockdata.experience_level}</h5>
                                </div>
                            </div>
                            {/*<div className='small-row'>
                                <BsClock className='check-sharp' />
                                <div className='small-col'>
                                    <h5 className='fourth-header'>Working Hours</h5>
                                    <h5 className='fifth-header'>9 AM - 6 PM</h5>
                                </div>
                            </div>
                            <div className='small-row'>
                                <WiDayFog className='check-sharp' />
                                <div className='small-col'>
                                    <h5 className='fourth-header'>Working Days</h5>
                                    <h5 className='fifth-header'>Weekly: 5 days</h5>
                                    <h5 className='fifth-header'>Weekend: Saturday, Sunday</h5>
                                </div>
                            </div>*/}
                        </motion.div>
                    </div>
                    <form className='career-form' onSubmit={onSubmit}>
                        <div className='career-rows'>
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                                y: { type: "spring", stiffness: 60 },
                                opacity: { duration: 1 },
                                ease: "easeIn",
                                delay: 0.9,
                                duration: 0.9
                            }} className='career-col'>
                                <label className='career-label' htmlFor='firstname'>First Name <span style={{
                                    color: "red"
                                }}>*</span></label>
                                <input type="text" className='career-input' name="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} required />
                            </motion.div>

                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                                y: { type: "spring", stiffness: 60 },
                                opacity: { duration: 1 },
                                ease: "easeIn",
                                delay: 0.5,
                                duration: 0.9
                            }} className='career-col'>
                                <label className='career-label' htmlFor='lastname'>Last Name <span style={{
                                    color: "red"
                                }}>*</span></label>
                                <input type="text" className='career-input' name="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} required />
                            </motion.div>

                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                                y: { type: "spring", stiffness: 60 },
                                opacity: { duration: 1 },
                                ease: "easeIn",
                                delay: 0.1,
                                duration: 0.9
                            }} className='career-col'>
                                <label className='career-label' htmlFor='emailaddress'>Email Address <span style={{
                                    color: "red"
                                }}>*</span></label>
                                <input type="email" className='career-input' name="emailaddress" value={email} onChange={(e) => setEmail(e.target.value)} required />
                            </motion.div>
                        </div>
                        <div className='career-rows'>
                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                                y: { type: "spring", stiffness: 60 },
                                opacity: { duration: 1 },
                                ease: "easeIn",
                                delay: 0.9,
                                duration: 0.9
                            }} className='career-col'>
                                <label className='career-label' htmlFor='phonenumber'>Phone number <span style={{
                                    color: "red"
                                }}>*</span></label>
                                <input type="text" className='career-input' name="phonenumber" value={phonenumber} onChange={(e) => setPhonenumber(e.target.value)} required />
                            </motion.div>

                            <motion.div initial={{ y: -100, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }} transition={{
                                y: { type: "spring", stiffness: 60 },
                                opacity: { duration: 1 },
                                ease: "easeIn",
                                delay: 0.5,
                                duration: 0.9
                            }} className='career-col'>
                                <label className='career-label' htmlFor='salary'>Salary <span style={{
                                    color: "red"
                                }}>*</span></label>
                                <input type="text" className='career-input' name="salary" value={salary} onChange={(e) => setSalary(e.target.value)} required />
                            </motion.div>

                          
                        </div>
                        <div className='labow'>
                            <div className='labe'>
                                <label className='career-labels' htmlFor="input-file" >
                                    Upload your resume <span style={{
                                        color: "red"
                                    }}>*</span>
                                </label>
                                <motion.div whileHover={{ scale: 1.03 }} transition={{
                                    y: { type: "spring", stiffness: 60 },
                                    opacity: { duration: 1 },
                                    ease: "easeIn",
                                    delay: 0.01,
                                    duration: 0.09
                                }} className='file-div' onClick={() => document.querySelector(".input-file").click()}>
                                    <input type='file' name='input-file' className='input-file' accept="application/pdf" onChange={handleFile} />
                                    {
                                        resumename ?
                                            <div>{resumename}</div> : <div className='file-divs'>
                                                <MdCloudUpload style={{ fontSize: "3rem", color: "#244886", marginBottom: 0 }} />
                                                <h3 className='file-upload'> Click to upload</h3>
                                            </div>
                                    }
                                </motion.div>
                            </div>
                            {/*<div className='labe'>
                                <label className='career-labels' htmlFor="cover-file" >
                                    Add a Cover letter
                                </label>
                                <motion.div whileHover={{ scale: 1.03 }} transition={{
                                    y: { type: "spring", stiffness: 60 },
                                    opacity: { duration: 1 },
                                    ease: "easeIn",
                                    delay: 0.01,
                                    duration: 0.09
                                }} className='file-div' onClick={() => document.querySelector(".cover-file").click()}>
                                    <input type='file' name='cover-file' className='cover-file' accept="application/pdf" onChange={handleFiles} />
                                    {
                                        covername ?
                                            <div>{covername}</div> : <div className='file-divs'>
                                                <MdCloudUpload style={{ fontSize: "3rem", color: "#244886", marginBottom: 0 }} />
                                                <h3 className='file-upload'> Click to upload</h3>
                                            </div>
                                    }
                                </motion.div>
                            </div>*/}
                        </div>

                        <input type='submit' value='Apply Now' />
                    </form>
                    {submit && <SimpleBackdrop />}
                    <Success isOpen={isSuccessOpen} onClose={
                        () => {
                            setIsSuccessOpen(!isSuccessOpen)
                            window.location.reload(false)
                            window.scrollTo(0, 0);
                        }
                    }>
                        <img src={Man} className='man' alt="3dman"></img>
                        <h3 className='over-head'>Thank you for applying!</h3>

                        <p className='overlay-body'>
                            Your application and resume have been received. We will review your submission and contact you if there is a match with our requirements.
                        </p>
                        <button className='career-sub' onClick={
                            () => {
                                setIsSuccessOpen(!isSuccessOpen)
                                window.location.reload(false)
                                window.scrollTo(0, 0);
                            }
                        }>close this window</button>
                    </Success>

                    <Failed isOpen={isFailedOpen} onClose={
                        () => {
                            setIsFailedOpen(!isFailedOpen)
                            window.location.reload(false)
                            window.scrollTo(0, 0);
                        }
                    }>
                        <img src={fail} className='memo' alt="notepad"></img>
                        <h3 className='over-head'>Request Failed</h3>
                        <p className='overlay-body'>
                            {errorMessage}
                        </p>
                        <button className='career-red' onClick={
                            () => {
                                setIsFailedOpen(!isFailedOpen)
                                window.location.reload(false)
                                window.scrollTo(0, 0);
                            }
                        }>close this window</button>
                    </Failed>
                </>
            }
            <Footer />
        </div >
    )
}

export default InCareer
