export const NotFound = () => {
    return (
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh"
        }}>
            <h1>Error 404: Page Not Found</h1>
        </div>
    );
}